import Layout from 'lib/components/Layout';

import Hero from './Hero';
import Pricing from './Pricing';
import Projects from './Projects';
import Services from './Services';
import Technologies from './Technologies';

import { base } from 'themes';
import { NAVIGATION_ITEMS, FOOTER_PROPS } from 'shared/constants';


const Home = () => (
  <Layout
    theme={base}
    navigation={NAVIGATION_ITEMS}
    footerProps={FOOTER_PROPS}
    logo={<img src="/brand.svg" alt="Logo" style={{ height: '40px' }} />}
  >
    <Hero />
    <Services />
    <Projects />
    <Technologies />
    <Pricing />
  </Layout>
);

export default Home;
