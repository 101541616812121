import { Grid, Typography } from '@mui/material';


const ServiceCard = ({ description, icon, title }) => (
  <Grid item md={4} sm={6}>
    {icon}
    <Typography gutterBottom variant="h6">
      {title}
    </Typography>
    <Typography variant="body2">
      {description}
    </Typography>
  </Grid>
);

export default ServiceCard;
