import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'

import Home from './Home'
import OpenAIPage from './OpenAIPage'

import './App.css'

function App() {
  console.log('enviroment: ', process.env.NODE_ENV)
  console.log('using the following setup: ', process.env)

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/openai" element={<OpenAIPage />} />

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
