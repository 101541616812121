import { Button, Link } from '@mui/material';

import { CALENDLY_LINK } from '../constants';

export default function DiscoveryButton(props) {
  const { size } = props;

  return (
    <Button
      color="primary"
      component={Link}
      href={CALENDLY_LINK}
      size={size}
      variant="contained"
      target="_blank"
    >
      Book Discovery Call
    </Button>
  )
};
