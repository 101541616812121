import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';

const ProjectCard = ({ description, image, title, url }) => {
  const hasActions = false
  const actionsComponent = hasActions && (
    <CardActions>
      <Button size="small">Share</Button>
      <Button size="small">Learn More</Button>
    </CardActions>
  )

  return (
    <Grid item md={4} sm={6} xs={12}>
      <Card>
        <CardActionArea
          href={url}
          target="_blank"
        >
          <CardMedia
            sx={{ height: 140 }}
            image={image}
            title={title}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div" color="primary">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
          {actionsComponent}
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default ProjectCard;
