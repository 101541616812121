import {
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import DiscoveryButton from 'shared/components/DiscoveryButton';


const PricingPack = ({ description, pack, price }) => (
  <Grid item md={3} sm={6} xs={12} >
    <Stack
      component={Paper}
      alignItems="center"
      justifyContent="space-between"
      sx={{ p: 3, height: 1 }}
      spacing={2}
    >
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
      >
        <Typography variant="body1">
          {pack}
        </Typography>
        <Typography variant="body2">
          {description}
        </Typography>
      </Stack>

      <Stack
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Typography variant="body1">
          Starting at
        </Typography>
        <Typography variant="h3">
          {price}
        </Typography>

        <DiscoveryButton />
      </Stack>
    </Stack>
  </Grid>
);

export default PricingPack;
