import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { COPY } from './content';


const SECTION_STYLES = {
  height: '100vh',
  backgroundImage: 'url(/heros/with_logo.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: 'white',
  textAlign: {
    xs: 'center',
    sm: 'left',
  },
};

function Hero() {
  const { taglines } = COPY;

  return (
    <Box
      component="section"
      id="main"
      sx={SECTION_STYLES}
    >
      <Container sx={{ height: '100%' }}>
        <Grid container
          height="100%"
          direction="column"
          justifyContent="center"
        >
          <Grid item>
            <img src="/brand.svg" alt="logo" />
            <Typography
              gutterBottom
              variant="h3"
              textAlign="right"
            >
              {taglines}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Hero;
