import {
  AdsClick,
  Code,
  Extension,
  PrecisionManufacturing,
  Psychology,
  VideoChat,
  Palette,
} from '@mui/icons-material';

const iconProps = {
  color: 'primary',
  sx: {
    height: 40,
    width: 40,
  },
};

export const SERVICES = [
  {
    title: "Landing Page",
    description: "Get a high converting website that displays the best side of your product.",
    display: true,
    icon: <Code {...iconProps} />,
  },
  {
    title: "Paid Media Management",
    description: "Want to reach out to more customers? Google Ads, Meta Ads, X Ads we work with them all.",
    display: true,
    icon: <AdsClick {...iconProps} />,
  },
  {
    title: "Brand Identity",
    description: "We can help you create a brand that stands out from the competition.",
    display: true,
    icon: <Palette {...iconProps} />,
  },
  {
    title: "Chrome Extension",
    description: "We can embed your idea into everybody life's with a Chrome Extension.",
    display: true,
    icon: <Extension {...iconProps} />,
  },
  {
    title: "Software Development",
    description: "We can build your product from scratch. From the first line of code to the last.",
    display: true,
    icon: <PrecisionManufacturing {...iconProps} />,
  },
  {
    title: "AI Prototype",
    description: "Do you have an idea for an AI powered project? Let's bring your idea to life.",
    display: true,
    icon: <Psychology {...iconProps} />,
  },
  {
    title: "Advertising",
    description: "Want to reach out to more customers? Increase your conversion rate?",
    display: false,
    icon: <Psychology {...iconProps} />,
  },
  {
    title: "Consulting",
    description: "You don't know if something can be implemented? Do you know how long it will take? Or how much will it cost? Ask me!",
    display: false,
    icon: <VideoChat {...iconProps} />,
  },
];
