export const CALENDLY_LINK = "https://calendly.com/d/cpgf-kb7-bsn/30-minute-discovery-call";

export const BRAND = 'appground';

export const NAVIGATION_ITEMS = [
  { anchor: '#services', text: 'Services', options: { sx: { color: 'white' } } },
  { anchor: '#projects', text: 'Portfolio', options: { sx: { color: 'white' } } },
  { anchor: '#pricing', text: 'Pricing', options: { sx: { color: 'white' } } },
  {
    anchor: 'https://calendly.com/d/cpgf-kb7-bsn/30-minute-discovery-call',
    text: 'Book Discovery Call',
    options: { color: "accent", variant: 'contained', target: '_blank' },
  },
];

export const FOOTER_PROPS = {
  topText: 'Made with ❤️ by',
  bottomText: 'appground media',
  url: '/',
};
