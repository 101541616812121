import {
  Container,
  Grid,
  Typography,
} from '@mui/material';

import ProjectCard from './ProjectCard';

import { PROJECTS } from './content';

const SECTION_STYLES = {
  pb: 10,
};

function Projects() {
  return (
    <Container
      component="section"
      id="projects"
      sx={SECTION_STYLES}
    >
      <Typography gutterBottom variant="h3">
        Recent projects
      </Typography>
      <Typography variant="body1" sx={{ pb: 5 }}>
        Explore some of our most recent projects
      </Typography>

      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        spacing={5}
      >
        {PROJECTS
          .filter(({display}) => display)
          .map(({description, image, title, url})=> (
            <ProjectCard
              key={title}
              description={description}
              image={image}
              title={title}
              url={url}
            />
          ))
        }
      </Grid>
    </Container>
  )
}

export default Projects;
