import {
  Container,
  Link,
  Grid,
  Typography,
} from '@mui/material';

import ServiceCard from './ServiceCard';

import { CALENDLY_LINK } from 'shared/constants';

import { SERVICES } from './content';


const SECTION_STYLES = {
  py: 12,
};

const Services = () => (
  <Container
    component="section"
    id="services"
    sx={SECTION_STYLES}
  >
    <Typography gutterBottom variant="h3">
      What can we do for you?
    </Typography>
    <Typography variant="body1" sx={{ pb: 5 }}>
      Validate your ideas, product and marketing strategy, full stack development, we got you covered.
    </Typography>

    <Grid container
      alignItems="stretch"
      justifyContent="center"
      spacing={5}
    >
      {SERVICES
        .filter(({display}) => display)
        .map(({
          description,
          icon,
          title,
        }) => (
          <ServiceCard
            description={description}
            icon={icon}
            key={title}
            title={title}
          />
        ))
      }
      <Grid item xs={12} textAlign="center">
        <Typography variant="body1">
          You don't see what you need?{'\n'}
          <Link href={CALENDLY_LINK}>
            Let's talk!
          </Link>
        </Typography>
      </Grid>
    </Grid>


  </Container>
);

export default Services;
