const primary = {
  main: 'hsl(275, 100%, 26%)',
};

const secondary = {
  main: 'hsl(256, 49%, 67%)',
};

const accent = {
  main: 'hsl(208, 18%, 16%)',
};

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

export const palette = {
  primary,
  secondary,
  accent,
  grey,
  blue,
};
