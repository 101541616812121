import {
  Box,
  Container,
  Stack,
  Typography,
} from '@mui/material';

const SECTION_STYLES = {
  backgroundColor: 'primary.main',
  pt: 12,
  textAlign: 'center',
};

const TAGLINE_STYLES = {
  backgroundColor: 'accent.main',
  py: 2,
  mt: 10,
  textAlign: 'center',
};

const Technologies = () => (
  <Box
    component="section"
    id="technologies"
    sx={SECTION_STYLES}
  >
    <Container>
      <Typography variant="h4" color="white">
        Some of the technologies in our toolbox
      </Typography>

      <Stack
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        spacing={5}
        sx={{ mt: 5 }}
      >
        <img src="/tech/react.svg" alt="React" height={64}/>
        <img src="/tech/firebase.svg" alt="Firebase" height={64} />
        <img src="/tech/unity.svg" alt="Unity3d" height={64} />
      </Stack>
    </Container>
    <Box sx={TAGLINE_STYLES}>
      <Typography variant="h6" color="white">
        PLAY LIKE THE BIGS DO
      </Typography>
    </Box>
  </Box>
);

export default Technologies;
