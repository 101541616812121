export const PRICING = [
  {
    display: true,
    pack: "Landing Page",
    price: "$400",
    description: "The best side of your product, live in less than 48 hours.",
  },
  {
    display: true,
    pack: "Chrome Extension",
    price: "$3999",
    description: "Transform everybody's browsing experience with your idea.",
  },
  {
    display: true,
    pack: "Product MVP",
    price: "$7500",
    description: "From idea to prototype to MVP in less than a month.",
  },
  {
    display: false,
    pack: "Something else in mind?",
    price: "Contact us",
    description: "We can make it happen.",
  },
];
