import {
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import PricingPack from './PricingPack';

import { PRICING } from './content';

import { CALENDLY_LINK } from 'shared/constants';

const SECTION_STYLES = {
  py: 12,
  textAlign: 'center',
};

function Pricing() {
  return (
    <Container
      component="section"
      id="pricing"
      sx={SECTION_STYLES}
    >
      <Typography variant="h4">
        Fixed Pricing for every project
      </Typography>
      <Typography variant="body1">
        Have something else in mind?{'\n'}
        <Link href={CALENDLY_LINK}>
          Let's talk!
        </Link>
      </Typography>

      <Grid container
        alignItems="stretch"
        justifyContent="center"
        pt={5}
        spacing={3}
      >
        {
          PRICING
            .filter(({display}) => display)
            .map((pack) => (
              <PricingPack
                key={pack.price}
                {...pack}
              />
            ))
        }
      </Grid>

      <Typography variant="body2" sx={{ pt: 3 }}>
        Prices are expressed in USD. Discovery calls are completely free.
      </Typography>
    </Container>
  )
}

export default Pricing;
