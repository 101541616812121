export const PROJECTS = [
  {
    display: true,
    description: "Website for an Up-and-Coming Augmented Reality Role-Playing Game.",
    image: "/projects/mutopi.png",
    title: "mutopi - Website",
    url: "https://mutopi.com",
  },
  {
    title: "Colorblindly",
    description: "Chrome extension that makes visited links on Google more accessible to colorblind people.",
    image: "/projects/colorblindly.jpeg",
    url: "https://chromewebstore.google.com/detail/colorblindly/linpjhmmfafpjdkpdhhnkjcecflbpjlf",
    display: true,
  },
  {
    title: "GEA Dubai - Website",
    description: "Web site for GEA Dubai - Global Entrepreneurship Academy Dubai.",
    image: "/projects/gea.png",
    url: "https://geadubai.org",
    display: true,
  },
  {
    display: false,
    image: "https://picsum.photos/536/354?random=4",
    title: "Project 4",
    url: "#projects",
  },
  {
    display: false,
    image: "https://picsum.photos/536/354?random=5",
    title: "Project 5",
    url: "#projects",
  },
];
