import { cloneElement } from 'react';
import { useScrollTrigger } from '@mui/material';


function ScrollTransformation(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const afterHero = useScrollTrigger({
    disableHysteresis: true,
    threshold: '890',
  });

  const {
    bgColorAfter,
    bgColorBefore,
    fadeIn,
    fadeOut,
    heightAfter,
    heightBefore,
    paddignTopAfter,
    smPaddingTopBefore,
    xsPaddingTopBefore,
  } = {
    bgColorAfter: 'linear-gradient(90deg, rgba(33,1,75,1) 0%, rgba(170,29,190,1) 39%, rgba(241,78,192,1) 86%, rgba(250,135,207,1) 100%)',
    bgColorBefore: 'transparent',
    fadeIn: '0.3s ease-in',
    fadeOut: '0.3s ease-out',
    heightAfter: 32,
    heightBefore: 56,
    paddignTopAfter: 0,
    smPaddingTopBefore: 3,
    xsPaddingTopBefore: 1,
  };

  return cloneElement(children, {
    ...children.props,
    logoStyles: {
      height: trigger ? heightAfter : heightBefore,
      style: {
        transition: trigger ? fadeIn : fadeOut,
      },
    },
    appBarStyles: {
      elevation: 0,
      sx: {
        background: afterHero ? bgColorAfter : bgColorBefore,
        transition: trigger ? fadeIn : fadeOut,
        backdropFilter: trigger ? 'blur(20px)' : 'none',
        pt: {
          xs: trigger ? paddignTopAfter : xsPaddingTopBefore,
          sm: trigger ? paddignTopAfter : smPaddingTopBefore,
        },
      },
    },
  });
};

export default ScrollTransformation;
